.container {
    width: 1em;
    padding: 0;
    border-radius: 0.5em;
    box-shadow: 0 0.1em 0 var(--button-shadow-color, rgb(252 123 123 / 1));

    &:active {
        box-shadow: 0 0.05em 0 var(--button-shadow-color, rgb(252 123 123 / 1));
        transform: translateY(0.05em);
    }
}

.shape {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}
