.container {
    min-height: 100vh;
    background: #fec;
}

.deviceContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 600px;
    transform: translate(-50%, -50%);
}

.device {
    padding: 30px 10px;
    border-radius: 0;
    background: #fec;
}

.screen {
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('./img/tile.png');
        background-position: top left;
        background-repeat: repeat;
        content: '';
        opacity: 0.2;
    }
}

.shape {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.message {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    color: #fff;
    font-size: 18px;
    text-align: center;

    .number {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.5em;
        background: #fff;
        color: var(--color-mollo);
    }
}

.name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background: #ff6a3b;
    color: #fff;
    font-size: 16px;
}

.camera {
    position: absolute;
    z-index: 2;
    right: 10px;
    bottom: 10px;
    font-size: 40px;

    .icon {
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
    }
}

.gauge {
    position: absolute;
    top: 0;
    left: 10px;
    width: calc(100% - 20px);
    padding-top: 10px;
    font-size: 10px;
    transform: translate(0, -100%);
    transition: transform 0.5s;

    .isPlaying & {
        transform: translate(0, 0);
    }
}

.kid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.logo {
    display: block;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 15px;
    transform: translateY(-20%);

    /* display: none; */
}

.bottom {
    position: relative;
}

.panel {
    display: flex;
    width: 100%;
    height: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.3em;
    margin-top: 20px;

    > * {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.panel {
    &.leave {
        position: absolute;
    }
}

.field {
    --control-background: rgba(255 255 255 / 0.5);

    width: 100%;
    height: 60px;
    font-size: 1.5rem;
}

.button {
    --button-color: #ff6a3b;
    --button-shadow-color: #7e1e00;

    color: #fff;
    font-size: 1rem;

    &.big {
        font-size: 1.2rem;
    }

    &.last {
        margin-top: 20px;
    }
}

.isLoading {
    .shape {
        > * {
            opacity: 0;
        }
    }

    .gauge {
        opacity: 0;
    }

    .bottom {
        opacity: 0;
    }
}

@media (--small-viewport) {
    .container {
        background: #ff6a3b;
    }

    .device {
        padding: 30px 40px;
        border-radius: 200px;
        box-shadow: 4px 4px 74px 10px rgb(30 30 30 / 0.3);
    }

    .screen {
        border-radius: 100px;
    }

    .field {
        width: 80%;
    }

    .name {
        bottom: 20px;
        left: 50px;
    }

    .gauge {
        left: 80px;
        width: calc(100% - 160px);
        padding-top: 10px;
    }

    .camera {
        right: 50px;
        bottom: 20px;
    }
}
