.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    position: relative;
    margin-right: 10px;
    font-size: 80px;

    &:last-child {
        margin-right: 0;
    }

    .icon {
        position: absolute;
        top: 8%;
        left: 10%;
        width: 80%;
    }
}
