.container {
    padding: 1em 2em 0.9em;
    border-radius: 2em;
    background: var(--button-color, rgb(255 187 187 / 1));
    box-shadow: 0 0.5em 0 var(--button-shadow-color, rgb(252 123 123 / 1));
    transition:
        transform 0.1s,
        box-shadow 0.1s;

    &:active {
        box-shadow: 0 0.1em 0 var(--button-shadow-color, rgb(252 123 123 / 1));
        transform: translateY(0.4em);
    }
}
