.container {
    --gauge-color: #ff6a3b;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ff6a3b;
}

.row {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
    color: #ff6a3b;

    &:last-child {
        margin-bottom: 0;
    }
}

.label {
    width: auto;
    margin-right: 0.2em;
    text-transform: uppercase;

    &.inline {
        display: inline;
    }
}

.progress {
    width: 3em;
    border: solid 1px #ff6a3b;
}

.bar {
    height: 1em;
    background: #ff6a3b;
}

.points {
    margin-top: 0.2em;
    margin-left: auto;
    font-size: 1.3rem;
    line-height: 1;

    span {
        display: inline-block;
        width: 0.6em;
    }
}

.urgent {
    animation: urgent-text 0.2s infinite;

    .progress {
        animation: urgent-progress 0.2s infinite;
    }

    .bar {
        animation: urgent-bar 0.2s infinite;
    }
}

@keyframes urgent-text {
    0% {
        color: #ff6a3b;
        transform: scale(1);
    }

    50% {
        color: #f00;
        transform: scale(1.1);
    }

    100% {
        color: #ff6a3b;
        transform: scale(1);
    }
}

@keyframes urgent-progress {
    0% {
        border-color: #ff6a3b;
    }

    50% {
        border-color: #f00;
    }

    100% {
        border-color: #ff6a3b;
    }
}

@keyframes urgent-bar {
    0% {
        background: #ff6a3b;
    }

    50% {
        background: #f00;
    }

    100% {
        background: #ff6a3b;
    }
}
